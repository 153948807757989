import React, { useEffect } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, Box, Typography, useMediaQuery } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PersonIcon from '@mui/icons-material/Person';
import logo from '../images/logo/LogoSajeBehari.jpeg';
import rehypeRaw from 'rehype-raw';

const ChatMessages = ({ conversationHistory, typingIndicator, MAX_MESSAGES }) => {
  useEffect(() => {
    if (conversationHistory.length > MAX_MESSAGES) {
      conversationHistory.splice(0, conversationHistory.length - MAX_MESSAGES);
    }
  }, [conversationHistory, MAX_MESSAGES]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const offWhite = '#F0F0F0';
  const lightGray = '#E0E0E0';
  const gold = '#C5B358';

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        padding: { xs: '10px', sm: '20px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
        overflowY: 'auto',
        paddingBottom: '110px',
      }}
    >
      <List sx={{ width: '100%', padding: 0 }}>
        {conversationHistory.map((entry, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              justifyContent: entry.role === 'user' ? 'flex-end' : (isMobile ? 'center' : 'flex-start'),
              mb: { xs: 1, sm: 2 },
            }}
          >
            {/* AI Avatar - Only render for non-mobile screens */}
            {!isMobile && entry.role === 'assistant' && (
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: '#5C6E87',
                    boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
                    width: { xs: 30, sm: 40 },
                    height: { xs: 30, sm: 40 },
                  }}
                >
                  <img 
                    src={logo} 
                    alt="VoteU Logo" 
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'contain' // Ensures the logo fits within the avatar
                    }} 
                  />
                </Avatar>
              </ListItemAvatar>
            )}

            {/* Message Box */}
            <Box
              sx={{
                backgroundColor: entry.role === 'user' ? gold : lightGray,
                color: entry.role === 'user' ? '#333' : '#000',
                padding: { xs: '2px 8px', sm: '8px 12px' }, // Reduce padding for smaller screens
                borderRadius: entry.role === 'user' ? '16px 16px 0 16px' : '16px 16px 16px 0',
                maxWidth: { xs: '85%', sm: '75%' },
                wordWrap: 'break-word',
                fontSize: { xs: '0.85rem', sm: '0.95rem' },
                lineHeight: 1.5,
                textAlign: entry.role === 'assistant' && isMobile ? 'left' : 'left', // Center text for mobile assistant messages
              }}
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{entry.content}</ReactMarkdown>
            </Box>

            {/* User Avatar */}
            {entry.role === 'user' && (
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: gold,
                    boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
                    width: { xs: 30, sm: 40 },
                    height: { xs: 30, sm: 40 },
                  }}
                >
                  <PersonIcon sx={{ fontSize: { xs: 20, sm: 24 } }} /> {/* Adjust icon size for mobile */}
                </Avatar>
              </ListItemAvatar>
            )}
          </ListItem>
        ))}

        {typingIndicator && (
          <Box display="flex" alignItems="center" mt={2} justifyContent={isMobile ? 'center' : 'flex-start'}>
            {/* AI Typing Avatar - Only render for non-mobile screens */}
            {!isMobile && (
              <Avatar
                sx={{
                  bgcolor: '#5C6E87',
                  width: { xs: 30, sm: 40 },
                  height: { xs: 30, sm: 40 },
                }}
              >
                <PersonIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
              </Avatar>
            )}
            <Box
              sx={{
                ml: 2,
                p: { xs: '6px 10px', sm: '12px 18px' },
                backgroundColor: offWhite,
                borderRadius: '16px 16px 16px 0',
                maxWidth: { xs: '65%', sm: '50%' },
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
                textAlign: 'center',
              }}
            >
              <Typography variant="body1" color="black">...</Typography>
            </Box>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default ChatMessages;
