import React from 'react';
import { Box, Button } from '@mui/material';

const PredefinedQuestions = ({ predefinedQuestions, handleQuestionSubmit, setQuestion }) => {
  if (!predefinedQuestions || predefinedQuestions.length === 0) {
    return null; 
  }

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      gap={1.5}
      sx={{
        p: 1,
        bgcolor: '#F0F0F0', 
        borderRadius: '8px',
        width: '100%',
        maxWidth: '800px',
        mx: 'auto',
        boxShadow: '0px', 
      }}
    >
      {predefinedQuestions.map((preQuestion, index) => (
        <Button
          key={index}
          variant="contained"
          onClick={() => {
            setQuestion(preQuestion);
            handleQuestionSubmit();
          }}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            px: 2,
            py: 1,
            fontSize: '0.9rem',
            fontWeight: 500,
            backgroundColor: '#C5B358',
            color: '#333333',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#B89C50',
              transform: 'translateY(-2px)',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.25)',
            },
          }}
        >
          {preQuestion}
        </Button>
      ))}
    </Box>
  );
};

export default PredefinedQuestions;
