import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ChatMessages from '../components/ChatMessages';
import ContactUsSection from '../components/ContactUsSection';
import NavBar from '../components/NavBar';
import BackgroundSvg from '../images/logo/voteu_background.svg';
import Particle from '../components/Particle';
import { ReactTyped as Typed } from 'react-typed';
import AutocompleteFeat from '../components/AutocompleteFeat';
import BallotModal from '../components/BallotModal';

const stateMappings = {
  'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California',
  'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia',
  'HI': 'Hawaii', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa', 'KS': 'Kansas',
  'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland', 'MA': 'Massachusetts',
  'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi', 'MO': 'Missouri', 'MT': 'Montana',
  'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire', 'NJ': 'New Jersey', 'NM': 'New Mexico',
  'NY': 'New York', 'NC': 'North Carolina', 'ND': 'North Dakota', 'OH': 'Ohio', 'OK': 'Oklahoma',
  'OR': 'Oregon', 'PA': 'Pennsylvania', 'RI': 'Rhode Island', 'SC': 'South Carolina',
  'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas', 'UT': 'Utah', 'VT': 'Vermont',
  'VA': 'Virginia', 'WA': 'Washington', 'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming'
};

const getStateFullName = (state) => {
  return stateMappings[state.toUpperCase()] || state; 
};

const LandingPage = () => {
  const [city, setCity] = useState('');
  const [state, setState] = useState(''); 
  const [ballotData, setBallotData] = useState([]); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [locationSet, setLocationSet] = useState(false); 
  const scrollViewRef = useRef(null); 

  const handleLocationSubmit = async () => {
    if (!city || !state) {
      setError('Please enter both city and state.');
      return;
    }

    const fullState = getStateFullName(state);
  
    setError('');
    setBallotData([]);
    setLoading(true);
  
    localStorage.setItem('userLocation', JSON.stringify({ city, state: fullState }));
    setLocationSet(true);
  
    try {
      console.log('Fetching ballot data from:', `${process.env.REACT_APP_API_URL}/election-data`);
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/election-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ city, state: fullState }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const formattedData = data.map((item) => ({
          role: 'assistant',
          content: `**${item.site}:**\n\n${item.cleanedData}\n\n**Related Links:**\n${item.links.map((link) => `- [${link}](${link})`).join('\n')}`,
          type: 'text',
        }));
        setBallotData(formattedData);
      } else {
        setError('Failed to retrieve ballot data. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while fetching the data.');
      console.error('Error during fetch:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('userLocation');
    };
  }, []);

  const aboutRef = useRef(null);
  const chatbotRef = useRef(null);
  const missionRef = useRef(null);
  const contactRef = useRef(null);
  const cityRef = useRef(null);

  const handleScroll = (section) => {
    if (section === 'about') {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'chatbot') {
      chatbotRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'mission') {
      missionRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'contact') {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs', 'sm')); // mobile 
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); //tablet

  return (
    <Box ref={chatbotRef} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  position: 'relative'}}>

    <NavBar handleScroll={handleScroll} style={{zIndex: 10}}/>
    
    <Particle />

      {/* Ballot Bot Section */}
      <Paper 
        sx={{
          p: 4,
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxWidth: '100%', 
          alignItems: 'center',
          textAlign: 'center',
          boxShadow: 'none',
          borderRadius: '12px',
          
         
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              color: '#333',
              textShadow: '0px 1px 2px',
              mb: 2,
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, 
            }}
          >
            <Typed
              strings={["Your vote matters."]}
              typeSpeed={40}
              startDelay={500}
              showCursor={false}
              loop={false}
            />
          </Typography>
          
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              color: '#333',
              mt: 2,
              textShadow: '0px 1px 2px',
              mb: 2,
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, 
            }}
          >
            <Typed
              strings={["So let’s make it well-informed."]}
              typeSpeed={40}
              startDelay={2000}
              showCursor={false}
              loop={false}
            />
          </Typography>
          
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#C5B358',
              mt: 3,
              textShadow: '0px 1px 2px',
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Adjust font size for different screen sizes
            }}
          >
            Learn More About Your Local Ballot using VoteU.AI
          </Typography>
        </Box>


        <Typography variant="body1" sx={{ mb: 4, color: '#333', fontWeight: 'bold' }}>
          Enter your city and state to discover detailed ballot information specific to your location
        </Typography>

        <Box sx={{ width: '75%', marginBottom: '20px' }}>
          <AutocompleteFeat
            onCitySelect={(city) => setCity(city)}
            onStateSelect={(state) => setState(state)}
            cityRef={cityRef}
          />
          <TextField
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
            sx={{ bgcolor: '#F0F0F0', marginBottom: '10px', borderRadius: '4px' }}
            style={{ display: 'none' }} 
            aria-hidden="true"
          />
          <TextField
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
            sx={{ bgcolor: '#F0F0F0', marginBottom: '10px', borderRadius: '4px' }}
            style={{ display: 'none' }} 
            aria-hidden="true"
          />
          <BallotModal />
          {/*<Button
            variant="contained"
            sx={{ backgroundColor: '#C5B358', color: '#FFFFFF', marginTop: '10px', padding: '10px 20px', borderRadius: '4px' }}
            onClick={handleLocationSubmit}
            disabled={loading} 
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Get Started with The Ballot Bot'}
          </Button> */}
          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
        </Box>

        {/* Location Set Confirmation */}
        {locationSet && (
          <Box sx={{ mt: 2, width: '100%' }}>
            <Typography variant="body1" sx={{ color: '#333' }}>
              Location set: {city}, {state}
            </Typography>
          </Box>
        )}

        {/* Ballot Data Section */}
        {ballotData.length > 0 && (
          <Box sx={{ zIndex: 9, backgroundColor: '#E0E0E0', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', textAlign: 'left', mt: 4, width: '100%', maxHeight: '600px', overflowY: 'auto' }}> {/* Height adjusted to be controlled here */}
            <ChatMessages sx={{ alignSelf:'center'}}
              conversationHistory={ballotData}
              typingIndicator={false}
              scrollViewRef={scrollViewRef}
              MAX_MESSAGES={20}
            />
          </Box>
        )}
      </Paper>

    {/* About Us Section */}
    <Box ref={aboutRef}
      sx={{
        width: 'fit-content',
        height: '100%',
        backgroundImage: isMobile
          ? 'none' // Remove background image for mobile
          : `url(${BackgroundSvg})`, // Use the SVG for non-mobile screens
        backgroundColor: isMobile ? '#efdfb2ff' : 'transparent', // Set the background color for mobile
        backgroundSize: isTablet ? 'contain' : 'cover', // Set 'contain' for tablet to avoid clipping, 'cover' otherwise
        backgroundRepeat: 'repeat-y',
        backgroundAttachment: isTablet ? 'scroll' : 'fixed', // Disable 'fixed' attachment for tablet to avoid clipping issues
        position: 'relative',
      }}
    >

        {/* Bot information section. Below the Ballot Bot section 
        <Box
          sx={{
            width: '75%',
            margin: '0 auto',
            padding: '80px 20px',
            backgroundColor: '#F5F5F5',
            color: '#333',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {/* Ballot Bot Section Header 
          <Typography variant="h2" gutterBottom sx={{ color: '#C5B358', fontWeight: 'bold', textAlign: 'center', maxWidth: '900px', fontSize: '2.8rem' }}>
            Become a more informed voter with VoteU.AI
          </Typography>*/}

          {/* Chat Bot Section 
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', maxWidth: '900px', fontSize: '1.3rem' }}>
            Chat with our AI-powered assistant to learn about candidates, policies, and how to register to vote.
          </Typography>
          <Button variant="contained" sx={{ backgroundColor: '#5C6E87', color: '#FFFFFF', marginTop: '20px', fontSize: '1rem' }} href="/chatbot">
            Get Started with the Chatbot
          </Button>*/}

          {/* Ballot Bot Section 
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', maxWidth: '900px', marginTop: '40px', fontSize: '1.3rem' }}>
            Want to know what’s on your local ballot? Discover key details with our Ballot Bot!
          </Typography>
          <Button variant="contained" onClick={() => cityRef.current.focus()}  sx={{ backgroundColor: '#5C6E87', color: '#FFFFFF', marginTop: '20px', fontSize: '1rem' }}>
            Get Started with The Ballot Bot
          </Button>
        </Box>*/}
        
        {/* What is a Ballot? Section
        <Box sx={{ width: '75%', margin: '0 auto', padding: '70px 40px', backgroundColor: '#A4B8C8', color: '#FFFFFF' }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold', fontSize: '2.4rem' }}>
            What is a Ballot?
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '30px', fontSize: '1.2rem' }}>
            A ballot is the method by which a voter makes their choices in an election. It can be a physical paper, a digital form, or a machine that records your selections for candidates, measures, or propositions. Ballots are designed to ensure that every vote counts, and it's an essential tool for making sure your voice is heard in the democratic process.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box>
              <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold', fontSize: '1.4rem' }}>Types of Ballots</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>
                There are different types of ballots, such as absentee ballots for those who cannot make it to the polling place, and provisional ballots for voters whose eligibility is in question.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold', fontSize: '1.4rem' }}>Why Your Ballot Matters</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>
                Your ballot allows you to influence government policies and elect representatives who align with your values. Each decision on the ballot can have a direct impact on your community and daily life.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold', fontSize: '1.4rem' }}>Tips for Making Informed Choices</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>
                Research candidates and measures ahead of time, understand the implications of each choice, and consider how each outcome might affect your community, state, and country.
              </Typography>
            </Box>
          </Box>
        </Box> */}
        {/* Our Mission Section */}
        <Box 
          ref={missionRef} 
          sx={{ 
            width: { xs: '90%', sm: '80%', md: '75%' }, // Adjust width for smaller screens
            margin: '0 auto', 
            padding: { xs: '40px 20px', sm: '60px 30px', md: '70px 40px' }, // Responsive padding
            backgroundColor: '#F5F5F5', 
            color: '#333333' 
          }}
        >
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              color: '#C5B358', 
              fontWeight: 'bold', 
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.4rem' }, // Responsive font sizes
            }}
          >
            Our Mission
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              marginBottom: '20px', 
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
            }}
          >
            We are revolutionizing voter education by making it easy for young people to access crucial information. With AI at the forefront, we provide real-time, personalized, and location-specific data so that you are always in the know about policies, politicians, and your nearest voting booths.
          </Typography>
        </Box>

        {/* Why VoteU Section */}
        <Box 
          sx={{ 
            width: { xs: '90%', sm: '80%', md: '75%' }, // Adjust width for smaller screens
            margin: '0 auto', 
            padding: { xs: '40px 20px', sm: '60px 30px', md: '70px 40px' }, // Responsive padding
            backgroundColor: '#5C6E87', 
            color: '#FFFFFF' 
          }}
        >
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              color: '#C5B358', 
              fontWeight: 'bold', 
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.4rem' }, // Responsive font sizes
            }}
          >
            Why VoteU?
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              marginBottom: '30px', 
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
            }}
          >
            VoteU is your personalized, AI-driven tool designed to make you an informed voter. Whether it's national elections or understanding local issues, VoteU provides you with key information that’s tailored to your interests and your location.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '20px', md: '30px' } }}>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#C5B358', 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1.2rem', md: '1.4rem' }, // Responsive font sizes
                }}
              >
                Personalized AI Insights
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.9rem', md: '1.1rem' }, // Responsive font sizes
                  color: '#FFFFFF' 
                }}
              >
                Our AI analyzes your interests and provides tailored voting information, from local elections to national policies.
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#C5B358', 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1.2rem', md: '1.4rem' }, // Responsive font sizes
                }}
              >
                Location-Specific Info
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.9rem', md: '1.1rem' }, // Responsive font sizes
                  color: '#FFFFFF' 
                }}
              >
                Find your polling place, learn about city-specific policies, and receive details about local politicians.
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#C5B358', 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1.2rem', md: '1.4rem' }, // Responsive font sizes
                }}
              >
                Stay Updated
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.9rem', md: '1.1rem' }, // Responsive font sizes
                  color: '#FFFFFF' 
                }}
              >
                Get timely updates on voter registration deadlines, local elections, and more — tailored just for you.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Meet The Team Section */}
        <Box 
          sx={{ 
            width: { xs: '90%', sm: '80%', md: '75%' }, // Adjust width for smaller screens
            margin: '0 auto', 
            backgroundColor: '#F5F5F5', 
            padding: { xs: '40px 20px', sm: '60px 30px', md: '70px 40px' } // Responsive padding
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              color: '#C5B358', 
              mb: 3, 
              fontWeight: 'bold', 
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.4rem' } // Responsive font sizes
            }}
          >
            Meet the Team
          </Typography>
          <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
            <Stack spacing={{ xs: 2, sm: 3 }}>
              <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                <strong>Amrik Chattha</strong> - Developer and AI Specialist
              </Typography>
              <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                <strong>Michael O'Neil</strong> - Project Manager and Head of Marketing
              </Typography>
              <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                <strong>Luke Aspatore</strong> - Backend Developer
              </Typography>
              <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                <strong>Clark Pillsbury</strong> - Frontend Developer
              </Typography>
              <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                <strong>Saje Behari</strong> - Lead Developer
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#333', 
                  mb: 2, 
                  fontSize: { xs: '0.9rem', sm: '1.1rem' } // Responsive font sizes
                }}
              >
                VoteU.AI is a web app developed by students <strong>Michael O’Neil</strong>, <strong>Amrik Chattha</strong>, <strong>Luke Aspatore</strong>, and <strong>Clark Pillsbury</strong> with the guidance of college student <strong>Saje Behari</strong> and three mayors in Marin county to provide unbiased political information and upcoming legislation to improve voter turnout rates in young Americans.
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* Footer Section */}
        <Box
          ref={contactRef}
          sx={{
            backgroundColor: '#E0E0E0',
            paddingTop: ' ', 
            paddingBottom: '40px', 
            textAlign: 'center',
            color: '#333',
            width: '100%',
          }}
        >
          {/* Contact Section 
          <ContactUsSection />
          <Typography
            variant="h6" 
            color="textPrimary"
            sx={{
              fontSize: '1.5rem', 
              margin: '0 auto',
              maxWidth: '60%',
              paddingX: '20px', 
              lineHeight: 1.7, 
              marginTop: '20px', 
            }}
          >
          </Typography>*/}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              fontSize: '1.5rem',
              paddingTop: '40px',
            }}
          >
            © 2024 VoteU.AI | All rights reserved. | Terms of Use | Privacy Policy 🇺🇸
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
