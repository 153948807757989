import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

export default function HomePage() {
  return (
    <Container disableGutters maxWidth={false} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box 
        sx={{ 
          textAlign: 'center', 
          padding: '50px 0', 
          backgroundColor: '#F5F5F5', 
          color: '#333', 
          flex: '1' 
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ color: '#C5B358' }}> 
          Become a more informed voter with VoteU.AI
        </Typography>
        <Typography variant="h6" gutterBottom>
          Stay informed and empowered with AI-backed information about elections, legislation, policies, and more.
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: '#C5B358', color: '#333333', marginTop: '20px' }} href="/chatbot">
          Get Started with the Chatbot
        </Button>
      </Box>

      <Box sx={{ padding: '60px 30px', backgroundColor: '#F0F0F0', color: '#333333', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#C5B358' }}>
          Our Mission
        </Typography>
        <Typography variant="body1" sx={{ margin: '0 auto', maxWidth: '800px' }}>
          We are revolutionizing voter education by making it easy for young people to access crucial information. With AI at the forefront, we provide real-time, personalized, and location-specific data so that you are always in the know about policies, politicians, and your nearest voting booths.
        </Typography>
      </Box>

      <Box sx={{ padding: '70px 30px', backgroundColor: '#FAFAFA', color: '#333333', textAlign: 'center' }}> 
        <Typography variant="h4" gutterBottom sx={{ color: '#C5B358' }}> 
          Why VoteU?
        </Typography>
        <Typography variant="body1" sx={{ margin: '0 auto', maxWidth: '800px', mb: 4 }}>
          VoteU is your personalized, AI-driven tool designed to make you an informed voter. Whether it's national elections or understanding local issues, VoteU provides you with key information that’s tailored to your interests and your location.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 3 }}>
          <Box sx={{ maxWidth: '300px', textAlign: 'left' }}>
            <Typography variant="h6" sx={{ color: '#C5B358' }}>Personalized AI Insights</Typography>
            <Typography variant="body2">Our AI analyzes your interests and provides tailored voting information, from local elections to national policies.</Typography>
          </Box>
          <Box sx={{ maxWidth: '300px', textAlign: 'left' }}>
            <Typography variant="h6" sx={{ color: '#C5B358' }}>Location-Specific Info</Typography>
            <Typography variant="body2">Find your polling place, learn about city-specific policies, and receive details about local politicians.</Typography>
          </Box>
          <Box sx={{ maxWidth: '300px', textAlign: 'left' }}>
            <Typography variant="h6" sx={{ color: '#C5B358' }}>Stay Updated</Typography>
            <Typography variant="body2">Get timely updates on voter registration deadlines, local elections, and more — tailored just for you.</Typography>
          </Box>
        </Box>
        <Button variant="contained" sx={{ backgroundColor: '#C5B358', color: '#333333', marginTop: '30px' }} href="/chatbot">
          Try VoteU Today
        </Button>
      </Box>

      <Box 
        sx={{ 
          backgroundColor: '#E0E0E0',  
          padding: '20px 0', 
          textAlign: 'center', 
          color: '#333', 
          marginTop: 'auto'  
        }}
      >
        <Typography variant="h6" sx={{ color: '#C5B358' }}>
          Contact Us
        </Typography>
        <Typography variant="body1">
          Get in touch with us at info@voteu.ai
        </Typography>
        <Typography variant="body2" color="textSecondary">
          © 2024 VoteU.ai. All rights reserved. | Terms of Use | Privacy Policy
        </Typography>
      </Box>
    </Container>
  );
}
