import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Paper } from '@mui/material';
import useElectionData from '../hooks/useElectionData';

const LocationPage = () => {
  const { fetchElectionData, data, error, loading } = useElectionData();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [locationSubmitted, setLocationSubmitted] = useState(false);

  useEffect(() => {
    if (locationSubmitted) {
      fetchElectionData(city, state);
    }
  }, [locationSubmitted, city, state, fetchElectionData]);

  const handleSubmit = () => {
    if (city && state) {
      setLocationSubmitted(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Paper elevation={3} sx={{ padding: '30px', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h4" gutterBottom>
          Find Election Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enter your city and state to get accurate election data.
        </Typography>
        
        {/* Location Inputs */}
        <TextField 
          label="City" 
          value={city} 
          onChange={(e) => setCity(e.target.value)} 
          fullWidth 
          margin="normal" 
        />
        <TextField 
          label="State" 
          value={state} 
          onChange={(e) => setState(e.target.value)} 
          fullWidth 
          margin="normal" 
        />
        
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '20px' }}>
          Submit
        </Button>
        
        {/* Display Election Data */}
        {loading && <Typography variant="body1">Loading election data...</Typography>}
        {error && <Typography variant="body1" color="error">{error}</Typography>}
        {data && !loading && !error && (
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="h6">Election Data for {city}, {state}:</Typography>
            {/* Render the election data */}
            {data.map((item, index) => (
              <Typography key={index} variant="body2">
                {item.cleanedData}
              </Typography>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default LocationPage;
