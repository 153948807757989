import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import logo from '../images/logo/VoteULogoSajeBehari.jpeg';

export default function NavBar({ handleScroll }) {
  return (
    <AppBar position="sticky" sx={{  zIndex: 10, backgroundColor: 'transparent', boxShadow: 'none'}}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <Button onClick={() => handleScroll('chatbot')} sx={{ color: '#C5B358', padding: '0'}}>
            <img src={logo} alt="VoteU Logo" style={{ opacity: '70%', height: '40px', marginRight: '10px', display: 'block', boxShadow: "2px 4px 8px"}} />
          </Button>
        </Typography>
        {/*<Box>
          <Button onClick={() => handleScroll('about')} sx={{ color: '#FAFAFA', marginRight: '15px' }}>
            About Us
          </Button>
          <Button onClick={() => handleScroll('mission')} sx={{ color: '#FAFAFA', marginRight: '15px' }}>
            Mission
          </Button>
          <Button onClick={() => handleScroll('contact')} sx={{ color: '#FAFAFA' }}>
            Contact Us
          </Button>
        </Box>*/}
      </Toolbar>
    </AppBar>
  );
}
