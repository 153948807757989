// useElectionData.js

import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const useElectionData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchElectionData = async (city, state) => {
    try {
      setLoading(true);
      setError(null);

      console.log('Using location data:', { city, state });

      // Log the payload being sent to the API
      const payload = JSON.stringify({ city, state });
      console.log('Payload being sent:', payload);

      const response = await fetch(`${process.env.REACT_APP_RENDER_URL}/election-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
      });

      // Log the response status and body
      console.log('Response status:', response.status);
      const result = await response.json();
      console.log('Response data:', result);

      setData(result);
    } catch (e) {
      setError(e.message);
      console.error('Error fetching election data:', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getStoredLocationAndFetchData = async () => {
      const location = await AsyncStorage.getItem('userLocation');
      if (location) {
        const { city, state } = JSON.parse(location);
        fetchElectionData(city, state); // Fetch data initially using saved location
      } else {
        setError('Location not found');
        console.error('Location not found in AsyncStorage');
      }
    };

    getStoredLocationAndFetchData();
  }, []);

  return { data, loading, error, fetchElectionData };  // Return fetchElectionData for manual usage
};

export default useElectionData;

// import { useState, useEffect } from 'react';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// const useElectionData = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // console.log('API URL:', process.env.REACT_APP_API_URL);  // Should log https://voteu.onrender.com/api
//     // console.log('Render URL:', process.env.REACT_APP_RENDER_URL);  // Should log https://voteu.onrender.com
    
//     const fetchElectionData = async () => {
//       try {
//         const location = await AsyncStorage.getItem('userLocation');
//         if (location) {
//           const { city, state } = JSON.parse(location);
//           console.log('Using location data:', { city, state });

//           // Log the payload being sent to the API
//           const payload = JSON.stringify({ city, state });
//           console.log('Payload being sent:', payload);

//           // const response = await fetch('http://localhost:5555/election-data', {
//             const response = await fetch(`${process.env.REACT_APP_RENDER_URL}/election-data`, {
//               method: 'POST',
//               headers: {
//                 'Content-Type': 'application/json',
//               },
//               body: payload,
//             });
            

//           // Log the response status and body
//           console.log('Response status:', response.status);
//           const result = await response.json();
//           console.log('Response data:', result);

//           setData(result);
//         } else {
//           setError('Location not found');
//           console.error('Location not found in AsyncStorage');
//         }
//       } catch (e) {
//         setError(e.message);
//         console.error('Error fetching election data:', e);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchElectionData();
//   }, []);

//   return { data, loading, error };
// };

// export default useElectionData;

