import React, { useRef } from 'react';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';

const AutocompleteFeat = ({ onCitySelect, onStateSelect }) => {
  const searchBoxRef = useRef(null);

  const handleLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const handlePlaceChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
  
        // Extracting city and state information from the selected place
        let city = '';
        let state = '';
        if (place.address_components) {
          place.address_components.forEach((component) => {
            if (component.types.includes('locality')) {
              city = component.long_name; // Extracts city if available
            } else if (component.types.includes('sublocality') && !city) {
              city = component.long_name; // Extract sublocality as city if locality is not set
            } else if (component.types.includes('neighborhood') && !city) {
              city = component.long_name; // Extract neighborhood as city if locality and sublocality are not set
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name; // Extract state abbreviation
            }
          });
        }
  
        // If no city was found, use the formatted address to try and extract a city-like value
        if (!city && place.formatted_address) {
          const parts = place.formatted_address.split(',');
          if (parts.length > 1) {
            city = parts[0].trim(); // Assign the first part as city
          }
        }
  
        // Set the selected city and state using the provided callback functions
        if (onCitySelect) onCitySelect(city);
        if (onStateSelect) onStateSelect(state);
      }
    }
  };
  

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} // Use the environment variable here
      libraries={['places']} // Load the "places" library to use Places Autocomplete
    >
      <StandaloneSearchBox
        onLoad={handleLoad}
        onPlacesChanged={handlePlaceChanged}
      >
        <input
          type="text"
          placeholder="Enter a city"
          style={{
            width: '100%',
            height: '40px',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
};

export default AutocompleteFeat;
