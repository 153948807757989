import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

const ContactUsPage = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.name) errors.name = 'Name is required!';
    if (!formValues.email) errors.email = 'Email is required!';
    if (!/\S+@\S+\.\S+/.test(formValues.email)) errors.email = 'Email is invalid';
    if (!formValues.message) errors.message = 'Message is required!';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      console.log('Form submitted successfully:', formValues);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#E0E0E0', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 20px',
        color: 'white',
      }}
    >
      {/* Contact Us Header */}
      <Typography
        variant="h3"
        sx={{
          color: '#C5B358',  
          fontWeight: 'bold',
          marginBottom: 4,
        }}
      >
        Contact Us
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="body1"
        sx={{
          color: '#333',
          marginBottom: 4,
          textAlign: 'center',
          maxWidth: '600px', 
        }}
      >
        Have questions or feedback? Fill out the form below, and our team will get back to you as soon as possible.
      </Typography>

      {/* Form */}
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '600px' }}>
        {/* Name Input */}
        <TextField
          label="Name"
          name="name"
          fullWidth
          value={formValues.name}
          onChange={handleInputChange}
          error={Boolean(formErrors.name)}
          helperText={formErrors.name}
          sx={{
            marginBottom: 3,
            '& .MuiInputLabel-root': { color: '#C5B358' }, 
            '& .MuiInputBase-input': { color: '#F5F5F5' },  
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#5C6E87', 
              },
              '&:hover fieldset': {
                borderColor: '#C5B358',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#C5B358',
              },
            },
          }}
        />

        {/* Email Input */}
        <TextField
          label="Email"
          name="email"
          fullWidth
          value={formValues.email}
          onChange={handleInputChange}
          error={Boolean(formErrors.email)}
          helperText={formErrors.email}
          sx={{
            marginBottom: 3,
            '& .MuiInputLabel-root': { color: '#C5B358' },
            '& .MuiInputBase-input': { color: '#F5F5F5' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#5C6E87',
              },
              '&:hover fieldset': {
                borderColor: '#C5B358',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#C5B358',
              },
            },
          }}
        />

        {/* Message Input */}
        <TextField
          label="Message"
          name="message"
          fullWidth
          multiline
          rows={4}
          value={formValues.message}
          onChange={handleInputChange}
          error={Boolean(formErrors.message)}
          helperText={formErrors.message}
          sx={{
            marginBottom: 3,
            '& .MuiInputLabel-root': { color: '#C5B358' },
            '& .MuiInputBase-input': { color: '#F5F5F5' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#5C6E87',
              },
              '&:hover fieldset': {
                borderColor: '#C5B358',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#C5B358',
              },
            },
          }}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#C5B358',
            color: '#1C1C1C',  
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: '#B59947',  
            },
          }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ContactUsPage;
