// Sidebar.jsx


import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import collegesData from '../pages/data/campus.json';
import useElectionData from '../hooks/useElectionData';

const Sidebar = ({ setPage, onLocationUpdate, setLoading }) => {
  const theme = useTheme();
  const [location, setLocation] = useState({ city: '', state: '' });
  const [colleges, setColleges] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState('');
  const [showCollegeSelect, setShowCollegeSelect] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { fetchElectionData } = useElectionData();

  useEffect(() => {
    const savedLocation = localStorage.getItem('userLocation');
    const savedCollege = localStorage.getItem('selectedCollege');

    if (savedLocation) {
      const { city, state } = JSON.parse(savedLocation);
      setLocation({ city, state });
      if (collegesData[state]) {
        setColleges(collegesData[state].map((college) => Object.keys(college)[0]));
        setShowCollegeSelect(true);
      }
    }

    if (savedCollege) {
      setSelectedCollege(savedCollege);
      setIsSubmitEnabled(true); // Enable submit if a college was previously selected
    }
  }, []);

  useEffect(() => {
    const formattedCity = location.city.trim();
    const formattedState = location.state.trim();
    if (formattedCity && formattedState && collegesData[formattedState]) {
      setColleges(collegesData[formattedState].map((college) => Object.keys(college)[0]));
      setShowCollegeSelect(true);
    } else {
      setShowCollegeSelect(false);
      setSelectedCollege('');
      setIsSubmitEnabled(false);
    }
  }, [location]);

  const formatText = (text) => {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '); // Preserves spaces between words
  };

  const handleLocationChange = (key, value) => {
    const formattedValue = formatText(value);
    setLocation((prev) => ({ ...prev, [key]: formattedValue }));
  };

  const handleCollegeChange = (e) => {
    setSelectedCollege(e.target.value);
    localStorage.setItem('selectedCollege', e.target.value);
    setIsSubmitEnabled(true);
  };

  const handleFinalSubmit = async () => {
    const formattedCity = formatText(location.city);
    const formattedState = formatText(location.state);

    setLoading(true); // Start the loading state

    try {
      const electionData = await fetchElectionData(formattedCity, formattedState);
      localStorage.setItem('userLocation', JSON.stringify({ city: formattedCity, state: formattedState }));
      onLocationUpdate(formattedCity, formattedState); // Pass correct location to parent
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

  // Handler for interactive text click
  const handleInteractiveTextClick = () => {
    setPage('about'); // For example, this navigates to the 'about' page
  };

  return (
    <Box
      component="nav"
      sx={{
        width: { xs: 220, sm: 260 },
        position: 'fixed',
        height: '100vh',
        backgroundColor: '#222',
        color: '#f0f0f0',
        padding: '20px',
        borderRight: `1px solid ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        {/* Logo */}
        <Box sx={{ mb: 3, textAlign: 'center' }}>
          <img src="/logo.png" alt="Logo" style={{ maxWidth: '50%', height: 'auto' }} />
        </Box>

        {/* Interactive Text */}
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Button
            onClick={handleInteractiveTextClick}
            sx={{
              textTransform: 'none',
              color: theme.palette.primary.main,
              '&:hover': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
              },
            }}
          >
            Your AI tool to become a more important voter
          </Button>
        </Box>

        {/* Location Selection */}
        <Typography variant="h6" sx={{ mb: 2, color: theme.palette.primary.main }}>
          Enter Your Location
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            value={location.city}
            onChange={(e) => handleLocationChange('city', e.target.value)}
            sx={{
              backgroundColor: '#2e2e2e',
              borderRadius: '8px',
              input: { color: '#f0f0f0' },
              label: { color: '#aaa' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: '#555' },
                '&:hover fieldset': { borderColor: theme.palette.primary.main },
                '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
              },
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            value={location.state}
            onChange={(e) => handleLocationChange('state', e.target.value)}
            sx={{
              backgroundColor: '#2e2e2e',
              borderRadius: '8px',
              input: { color: '#f0f0f0' },
              label: { color: '#aaa' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: '#555' },
                '&:hover fieldset': { borderColor: theme.palette.primary.main },
                '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
              },
            }}
          />
        </FormControl>

        {showCollegeSelect && (
          <>
            <Typography variant="h6" sx={{ mb: 1, color: theme.palette.primary.main }}>
              Select College
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Select
                value={selectedCollege}
                onChange={handleCollegeChange}
                displayEmpty
                disabled={colleges.length === 0}
                sx={{
                  backgroundColor: '#2e2e2e',
                  color: '#f0f0f0',
                  '& .MuiSelect-icon': { color: '#f0f0f0' },
                }}
              >
                <MenuItem value="" disabled>
                  {colleges.length > 0 ? 'Choose College' : 'No Colleges Available'}
                </MenuItem>
                {colleges.map((college) => (
                  <MenuItem key={college} value={college}>
                    {college}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {isSubmitEnabled && (
              <Button
                variant="contained"
                onClick={handleFinalSubmit}
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.primary.main,
                  color: '#222',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </Box>

      {/* Menu Items */}
      <Box>
        <List>
          <ListItem
            button
            onClick={() => setPage('about')}
            sx={{
              padding: '10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#222',
              },
            }}
          >
            <ListItemText primary="About Us" sx={{ color: '#f0f0f0' }} />
          </ListItem>
          <ListItem
            button
            onClick={() => setPage('legislation')}
            sx={{
              padding: '10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#222',
              },
            }}
          >
            <ListItemText primary="Chatbot" sx={{ color: '#f0f0f0' }} />
          </ListItem>
        </List>

        {/* Logout Button */}
        <Button
          variant="contained"
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            color: '#222',
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;
