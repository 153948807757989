// useSendConversation.js


import { useState } from 'react';

// Hook to handle sending conversation and question to the backend
const useSendConversation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to generate a new conversation ID and store it in local storage if needed
  const generateConversationId = () => {
    let conversationId = localStorage.getItem('conversationId');
    if (!conversationId) {
      conversationId = Math.random().toString(36).substr(2, 9); // Generate random ID
      localStorage.setItem('conversationId', conversationId);
    }
    return conversationId;
  };

  const sendConversation = async (conversation, question) => { // Keep conversation as a parameter
    setLoading(true);
    setError(null);

    const conversationId = generateConversationId(); // Get or generate conversation ID

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/conversation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ conversationId, conversation, question }), // Send conversationId, conversation, and question
      });

      if (!response.ok) {
        throw new Error('Failed to send the conversation.');
      }

      const data = await response.json();
      return data.response;

    } catch (err) {
      setError('Failed to send the conversation.');
      console.error(err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { sendConversation, loading, error };
};

export default useSendConversation;


// import { useState } from 'react';

// const useSendConversation = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const sendConversation = async (conversation, question) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/conversation`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ conversation, question }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to send the conversation.');
//       }

//       const data = await response.json();
//       return data.response;

//     } catch (err) {
//       setError('Failed to send the conversation.');
//       console.error(err);
//       return null;
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { sendConversation, loading, error };
// };

// export default useSendConversation;
