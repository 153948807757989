import React, { useState } from 'react';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import PredefinedQuestions from './PredefinedQuestions';

const QuestionInput = ({ question, setQuestion, handleQuestionSubmit, sending, predefinedQuestions }) => {
  const [error, setError] = useState(null); 

  const handleSubmit = async () => {
    try {
      await handleQuestionSubmit();
      setError(null);  
    } catch (e) {
      setError('Failed to send question. Please try again.');  
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: 10, md: 20 },
        left: '50%',
        transform: 'translateX(-50%)',
        width: { xs: '95%', sm: '90%', md: '80%' },
        maxWidth: '800px',
        backgroundColor: '#F0F0F0',
        borderRadius: '16px',
        padding: { xs: '12px', md: '16px' },
        zIndex: 1000,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask anything..."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          sx={{
            backgroundColor: '#FFFFFF',  // White background for input field
            borderRadius: '8px',
            input: {
              color: '#333333',  // Darker text color for contrast
              padding: { xs: '8px', md: '10px' },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#C5B358',  // Gold border for inputs
              },
              '&:hover fieldset': {
                borderColor: '#C5B358',  // Gold hover effect
              },
              '&.Mui-focused fieldset': {
                borderColor: '#C5B358',  // Gold when focused
              },
            },
          }}
        />

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#C5B358',  // Gold accent for button
            color: '#333333',
            borderRadius: '8px',
            fontWeight: 600,
            padding: { xs: '10px 16px', md: '12px 24px' },
            '&:hover': {
              backgroundColor: '#B89C50',  // Slightly darker gold on hover
            },
            minWidth: '100px',
          }}
          onClick={handleSubmit}
          disabled={sending}
        >
          {sending ? <CircularProgress size={24} color="inherit" /> : 'Send'}
        </Button>
      </Box>

      {/* Error Message */}
      {error && (
        <Box sx={{ color: 'red', mt: 1, textAlign: 'center' }}>
          {error}
        </Box>
      )}

      {!question && (
        <Box
          sx={{
            mt: 2,
            width: '100%',
            textAlign: 'center',
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <PredefinedQuestions
            predefinedQuestions={predefinedQuestions}
            handleQuestionSubmit={handleSubmit}
            setQuestion={setQuestion}
          />
        </Box>
      )}
    </Box>
  );
};

export default QuestionInput;
