import React from 'react';
import { Container, Box, Typography, Stack } from '@mui/material';

export default function MissionScreen() {
  return (
    <Container disableGutters maxWidth={false} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      
      {/* Hero Section - Our Mission */}
      <Box sx={{ backgroundColor: '#E0E0E0', padding: '50px 20px', width: '100%', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ color: '#C5B358', mb: 2, fontWeight: 'bold' }}> 
          Our Mission
        </Typography>
        <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}> 
          <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
            VoteU.AI is a nonprofit program developed by students Michael O’Neil, Amrik Chattha, Luke Aspatore, and Clark Pillsbury to provide unbiased political information and upcoming legislation to improve voter turnout rates in young Americans.
          </Typography>
          <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
            It uses AI to do the heavy lifting for college students who want to get involved politically, find local elections, and propositions, and educate users on how they can make a difference. <strong style={{ color: '#C5B358' }}>Remember: YOUR VOTE MATTERS!!</strong>
          </Typography>
          <Typography variant="body1" sx={{ color: '#333' }}>
            We are revolutionizing voter education by making it easy for young people to access crucial information. With AI at the forefront, we provide real-time, personalized, and location-specific data so that you are always in the know about policies, politicians, and your nearest voting booths.
          </Typography>
        </Box>
      </Box>

      {/* Section: The Team */}
      <Box sx={{ backgroundColor: '#F5F5F5', padding: '50px 20px', width: '100%', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ color: '#C5B358', mb: 3, fontWeight: 'bold' }}> {/* Bold header */}
          Meet the Team
        </Typography>
        <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
          <Stack spacing={3}>
            <Typography variant="body1" sx={{ color: '#333' }}>
              <strong>Michael O'Neil</strong> 
            </Typography>
            <Typography variant="body1" sx={{ color: '#333' }}>
              <strong>Amrik Chattha</strong> 
            </Typography>
            <Typography variant="body1" sx={{ color: '#333' }}>
              <strong>Luke Aspatore</strong> 
            </Typography>
            <Typography variant="body1" sx={{ color: '#333' }}>
              <strong>Clark Pillsbury</strong> 
            </Typography>
          </Stack>
        </Box>
      </Box>

      {/* Section: Our Impact */}
      <Box sx={{ backgroundColor: '#5C6E87', padding: '50px 20px', width: '100%', textAlign: 'center' }}> 
        <Typography variant="h4" sx={{ color: '#C5B358', mb: 3, fontWeight: 'bold' }}> 
          Our Impact
        </Typography>
        <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
          <Typography variant="body1" sx={{ color: '#FAFAFA', mb: 2 }}> 
            We aim to reach millions of young Americans who are eager to vote but lack the information to make informed decisions. With our platform, we make it easier than ever for the next generation of voters to access the information they need to make a difference.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
