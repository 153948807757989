import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import HomePage from './pages/homepage';
import LegislationScreen from './pages/LegislationScreen';
import MissionScreen from './pages/MissionScreen';
import ChatbotPage from './pages/ChatbotPage'; 
import AboutUsPage from './pages/AboutUsPage'; 
import ContactUsPage from './pages/ContactUsPage'; 
import LandingPage from './pages/LandingPage'; 
import LocationPage from './pages/LocationPage';
import NavBar from './components/NavBar';

const theme = createTheme();

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {/* <NavBar /> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/legislation" element={<LegislationScreen />} />
          <Route path="/mission" element={<MissionScreen />} />
          <Route path="/chatbot" element={<ChatbotPage />} />
          <Route path="/location" element={<LocationPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}