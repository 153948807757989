import React, { useState, useRef, useEffect } from 'react';
import { Paper, Box, TextField, Button, Typography } from '@mui/material';
import ChatMessages from '../components/ChatMessages';
import QuestionInput from '../components/QuestionInput';
import useSendConversation from '../hooks/useSendConversation';

const ChatbotPage = () => {
  const { sendConversation, loading: sending } = useSendConversation();
  const [question, setQuestion] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [locationSet, setLocationSet] = useState(false);
  const scrollViewRef = useRef(null);
  const MAX_MESSAGES = 20;

  const offWhite = '#F0F0F0';  
  const lightGray = '#E0E0E0'; 

  const predefinedQuestions = [
    "What are the key issues in this election?",
    "How do I register to vote?",
    "Where is my polling place?",
    "What are the candidates' positions?"
  ];

  useEffect(() => {
    localStorage.removeItem('userLocation'); 

    const savedLocation = localStorage.getItem('userLocation');
    if (savedLocation) {
      const { city, state } = JSON.parse(savedLocation);
      setCity(city);
      setState(state);
      setLocationSet(true);
    } else {
      setLocationSet(false);
    }
  }, []);

  const handleLocationSubmit = () => {
    if (city && state) {
      localStorage.setItem('userLocation', JSON.stringify({ city, state }));
      setLocationSet(true);
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        {
          role: 'assistant',
          content: `Location set: ${city}, ${state}. You can now get location-specific voter information.`,
          type: 'text',
        },
      ]);
    }
  };

  const handleQuestionSubmit = async () => {
    if (!question) return;

    const messageToSend = locationSet
      ? `Location: ${city}, ${state}. Question: ${question}`
      : question;

    const response = await sendConversation(conversationHistory, messageToSend);

    if (response) {
      updateConversationHistory(question, response);
      setQuestion(''); 
    }
  };

  const updateConversationHistory = (userMessage, assistantMessage) => {
    if (userMessage) {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: 'user', content: userMessage, type: 'text' },
      ]);
    }
    simulateTypingEffect(assistantMessage);
  };

  const simulateTypingEffect = (assistantMessage) => {
    let typingIndex = 0;
    let currentMessage = '';

    const typingInterval = setInterval(() => {
      if (typingIndex < assistantMessage.length) {
        currentMessage += assistantMessage[typingIndex];
        typingIndex++;

        setConversationHistory((prevHistory) => {
          const lastMessage = prevHistory[prevHistory.length - 1];
          if (lastMessage && lastMessage.role === 'assistant') {
            return [...prevHistory.slice(0, -1), { ...lastMessage, content: currentMessage }];
          } else {
            return [...prevHistory, { role: 'assistant', content: currentMessage, type: 'text' }];
          }
        });
      } else {
        clearInterval(typingInterval);
      }
    }, 10);
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: offWhite, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Paper
        sx={{
          p: 4,
          width: '100%',
          backgroundColor: 'transparent', 
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '95%',
          alignItems: 'center',
        }}
      >
        {/* Combined Location Input and Chat Interface */}
        <Box sx={{ width: '100%', marginBottom: '20px', display: locationSet ? 'none' : 'block' }}>
          <Typography variant="body1" color="#333333" gutterBottom>
            Want more accurate local voting information? Enter your city and state below, or continue without setting a location:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <TextField
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ bgcolor: offWhite }} 
            />
            <TextField
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ bgcolor: offWhite }}
            />
            <Button
              variant="contained"
              sx={{ backgroundColor: '#C5B358', color: '#333', marginTop: '10px' }}
              onClick={handleLocationSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>

        {/* Chat Interface */}
        <Box sx={{ flexGrow: 1, width: '100%', overflowY: 'auto', maxHeight: '400px', mb: 2 }}>
          <ChatMessages
            conversationHistory={conversationHistory}
            scrollViewRef={scrollViewRef}
            MAX_MESSAGES={MAX_MESSAGES}
            colorScheme={{ primary: '#C5B358', background: offWhite, text: '#333' }}  
          />
        </Box>

        {/* Input Box */}
        <Box sx={{ width: '100%', position: 'relative', paddingBottom: '10px' }}>
          <QuestionInput
            question={question}
            setQuestion={setQuestion}
            handleQuestionSubmit={handleQuestionSubmit}
            sending={sending}
            predefinedQuestions={predefinedQuestions} 
            colorScheme={{ primary: '#C5B358', background: lightGray, text: '#333' }} 
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ChatbotPage;
