// LegislationScreen.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Container, Paper, CircularProgress, Box, Typography, useMediaQuery, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../components/Sidebar';
import ChatMessages from '../components/ChatMessages';
import QuestionInput from '../components/QuestionInput';
import useElectionData from '../hooks/useElectionData';
import useSendConversation from '../hooks/useSendConversation';
import AboutUsPage from './AboutUsPage';
import ContactUsPage from './ContactUsPage';

const LegislationScreen = () => {
  const { data, error, fetchElectionData } = useElectionData();
  const { sendConversation, loading: sending } = useSendConversation();

  const [question, setQuestion] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [typingIndicator, setTypingIndicator] = useState(false);
  const [electionDataLoaded, setElectionDataLoaded] = useState(false);
  const [predefinedQuestionsHidden, setPredefinedQuestionsHidden] = useState(false);
  const [page, setPage] = useState('legislation');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state to show spinner during data fetch
  const [location, setLocation] = useState({ city: '', state: '' });
  const scrollViewRef = useRef(null);
  const MAX_MESSAGES = 20;

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const savedLocation = localStorage.getItem('userLocation');
    if (savedLocation) {
      const { city, state } = JSON.parse(savedLocation);
      setLocation({ city, state });

      if (data && data.length > 0 && !electionDataLoaded) {
        setElectionDataLoaded(true);
        addInitialMessage();
      }
    } else {
      addNoLocationMessage(); // If no location is found, show a warning
    }
  }, [data, electionDataLoaded]);

  const predefinedQuestions = [
    "What are the issues in this election?",
    "How do I register to vote?",
    "Where is my polling place?",
    "What are the candidates' positions?",
  ];

  const addInitialMessage = () => {
    const initialMessage = {
      role: 'assistant',
      content: `Election data has been loaded successfully for ${location.city}, ${location.state}. What would you like to know?`,
      type: 'text',
    };
    setConversationHistory([initialMessage]); // Reset history to the initial message
  };

  const addNoLocationMessage = () => {
    const warningMessage = {
      role: 'assistant',
      content: "No location selected. Select your location from the menu.",
      type: 'text',
    };
    setConversationHistory([warningMessage]); // Reset history to the no-location message
  };

  const handleQuestionSubmit = async () => {
    if (!question || !data) return;

    setPredefinedQuestionsHidden(true);

    const recentMessages = getRecentMessages();

    const filteredData = data
      .filter((item) => item && item.cleanedData && typeof item.cleanedData === 'string')
      .map((item) => ({
        role: 'assistant',
        content: item.cleanedData,
        type: 'text',
      }));

    const response = await sendConversation([...recentMessages, ...filteredData], question);

    if (response) {
      updateConversationHistory(question, response);
      setQuestion(''); // Clear input after successful submission
    }
  };

  const updateConversationHistory = (userMessage, assistantMessage) => {
    if (userMessage) {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: 'user', content: userMessage, type: 'text' },
      ]);
    }
    simulateTypingEffect(assistantMessage);
  };

  const simulateTypingEffect = (assistantMessage) => {
    let typingIndex = 0;
    let currentMessage = '';

    const typingInterval = setInterval(() => {
      if (typingIndex < assistantMessage.length) {
        currentMessage += assistantMessage[typingIndex];
        typingIndex++;

        setConversationHistory((prevHistory) => {
          const lastMessage = prevHistory[prevHistory.length - 1];
          if (lastMessage && lastMessage.role === 'assistant') {
            return [...prevHistory.slice(0, -1), { ...lastMessage, content: currentMessage }];
          } else {
            return [...prevHistory, { role: 'assistant', content: currentMessage, type: 'text' }];
          }
        });
      } else {
        clearInterval(typingInterval);
      }
    }, 10);
  };

  const getRecentMessages = () => {
    return conversationHistory.slice(-MAX_MESSAGES).map((message) => ({
      ...message,
      type: message.type || 'text',
    }));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLocationUpdate = async (newCity, newState) => {
    setLocation({ city: newCity, state: newState });
    setConversationHistory([]); // Clear previous chat history when location is updated
    setElectionDataLoaded(false); // Reset the data loaded state

    setLoading(true); // Set loading while fetching new data
    try {
      await fetchElectionData(newCity, newState); // Fetch new data for updated location
      setElectionDataLoaded(true); // Mark data as loaded
      addInitialMessage(); // Add initial message with new location
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <Box display="flex" sx={{ minHeight: '100vh', backgroundColor: '#1C1C1C' }}>
      {!isMobile ? (
        <Sidebar setPage={setPage} onLocationUpdate={handleLocationUpdate} setLoading={setLoading} />
      ) : (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', top: 10, left: 10, zIndex: 1300 }}
        >
          <MenuIcon sx={{ color: 'white' }} />
        </IconButton>
      )}

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250,
            backgroundColor: '#1C1C1C',
            color: 'white',
          },
        }}
      >
        <Sidebar setPage={setPage} onLocationUpdate={handleLocationUpdate} setLoading={setLoading} />
      </Drawer>

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: 1,
          padding: '0 20px',
          backgroundColor: '#1C1C1C',
        }}
      >
        {page === 'about' && <AboutUsPage />}
        {page === 'contact' && <ContactUsPage />}
        {page === 'legislation' && (
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: '100%',
              maxWidth: '95%',
              backgroundColor: '#1C1C1C',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                <CircularProgress color="primary" />
              </Box>
            ) : error ? (
              <Typography variant="body1" color="error" align="center">
                {error}
              </Typography>
            ) : (
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <ChatMessages
                    conversationHistory={conversationHistory}
                    typingIndicator={typingIndicator}
                    scrollViewRef={scrollViewRef}
                    MAX_MESSAGES={MAX_MESSAGES}
                  />
                </Box>

                <Box sx={{ position: 'relative', width: '100%' }}>
                  <Box sx={{ position: 'absolute', bottom: '0px', width: '100%', px: 2 }}>
                    <QuestionInput
                      question={question}
                      setQuestion={setQuestion}
                      handleQuestionSubmit={handleQuestionSubmit}
                      sending={sending}
                      predefinedQuestions={predefinedQuestionsHidden ? [] : predefinedQuestions}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Paper>
        )}
      </Container>
    </Box>
  );
};

export default LegislationScreen;














// // LegislationScreen.jsx




// import React, { useState, useEffect, useRef } from 'react';
// import { Container, Paper, CircularProgress, Box, Typography, useMediaQuery } from '@mui/material';
// import Sidebar from '../components/Sidebar';
// import ChatMessages from '../components/ChatMessages';
// // import PredefinedQuestions from '../components/PredefinedQuestions';
// import QuestionInput from '../components/QuestionInput';
// import useElectionData from '../hooks/useElectionData';
// import useSendConversation from '../hooks/useSendConversation';

// const LegislationScreen = () => {
//   const { data, loading, error } = useElectionData();
//   const { sendConversation, loading: sending } = useSendConversation();

//   const [question, setQuestion] = useState("");
//   const [conversationHistory, setConversationHistory] = useState([]);
//   const [typingIndicator, setTypingIndicator] = useState(false);
//   const [electionDataLoaded, setElectionDataLoaded] = useState(false);
//   const [showPredefinedQuestions, setShowPredefinedQuestions] = useState(true); // New state to control visibility
//   const scrollViewRef = useRef(null);
//   const MAX_MESSAGES = 20;

//   // Use media query to detect mobile view
//   const isMobile = useMediaQuery('(max-width:600px)');

//   const predefinedQuestions = [
//     "What are the key issues in this election?",
//     "How do I register to vote?",
//     "Where is my polling place?",
//     "What are the candidates' positions?",
//   ];

//   useEffect(() => {
//     if (data && data.length > 0 && !electionDataLoaded) {
//       setElectionDataLoaded(true);
//       addInitialMessage();
//     }
//   }, [data, electionDataLoaded]);

//   const addInitialMessage = () => {
//     const initialMessage = {
//       role: 'assistant',
//       content: "Election data has been loaded successfully. What would you like to know?",
//       type: 'text',
//     };
//     setConversationHistory((prevHistory) => [...prevHistory, initialMessage]);
//   };

//   const handleQuestionSubmit = async () => {
//     if (!question || !data) return;

//     setShowPredefinedQuestions(false); // Hide predefined questions after the first message is sent

//     const recentMessages = getRecentMessages();

//     const filteredData = data
//       .filter((item) => item && item.cleanedData && typeof item.cleanedData === 'string')
//       .map((item) => ({
//         role: 'assistant',
//         content: item.cleanedData,
//         type: 'text',
//       }));

//     const response = await sendConversation([...recentMessages, ...filteredData], question);

//     if (response) {
//       updateConversationHistory(question, response);
//       setQuestion(""); // Clear input after successful submission
//     }
//   };

//   const updateConversationHistory = (userMessage, assistantMessage) => {
//     if (userMessage) {
//       setConversationHistory((prevHistory) => [
//         ...prevHistory,
//         { role: 'user', content: userMessage, type: 'text' },
//       ]);
//     }
//     simulateTypingEffect(null, assistantMessage);
//   };

//   const simulateTypingEffect = (userMessage, assistantMessage) => {
//     let typingIndex = 0;
//     let currentMessage = "";

//     const typingInterval = setInterval(() => {
//       if (typingIndex < assistantMessage.length) {
//         currentMessage += assistantMessage[typingIndex];
//         typingIndex++;

//         setConversationHistory((prevHistory) => {
//           const lastMessage = prevHistory[prevHistory.length - 1];
//           if (lastMessage && lastMessage.role === 'assistant') {
//             return [
//               ...prevHistory.slice(0, -1),
//               { ...lastMessage, content: currentMessage },
//             ];
//           } else {
//             return [
//               ...prevHistory,
//               { role: 'assistant', content: currentMessage, type: 'text' },
//             ];
//           }
//         });
//       } else {
//         clearInterval(typingInterval);
//       }
//     }, 10);
//   };

//   const getRecentMessages = () => {
//     return conversationHistory.slice(-MAX_MESSAGES).map((message) => ({
//       ...message,
//       type: message.type || 'text',
//     }));
//   };

//   return (
//     <Box display="flex" sx={{ minHeight: '100vh', backgroundColor: '#1C1C1C' }}>  
      
//       {/* Conditionally render Sidebar based on screen size */}
//       {!isMobile && <Sidebar />}

//       <Container
//         maxWidth="lg"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'flex-start',
//           flex: 1,
//           padding: '0 20px',
//           backgroundColor: '#1C1C1C',
//         }}
//       >
     

//         <Paper
//           elevation={3}
//           sx={{
//             p: 4,
//             width: '100%',
//             maxWidth: '95%',
//             backgroundColor: '#1C1C1C',
//             borderRadius: '12px',
//             boxShadow: "none",
//             display: 'flex',
//             flexDirection: 'column',
//             position: 'relative',
//             flexGrow: 1,
//             overflow: 'hidden',
//             maxHeight: '80vh',
//             marginLeft: 'auto',
//             marginRight: 'auto',
//           }}
//         >
//           {loading ? (
//             <Box display="flex" justifyContent="center" alignItems="center" height="300px">
//               <CircularProgress color="primary" />
//             </Box>
//           ) : error ? (
//             <Typography variant="body1" color="error" align="center">
//               {error}
//             </Typography>
//           ) : null}

//           {/* Chat Messages will scroll */}
//           <Box
//             sx={{
//               flexGrow: 1,
//               overflowY: 'auto',
//               paddingBottom: '90px',
//               backgroundColor: '#1C1C1C',
//               '::-webkit-scrollbar': { width: '8px', visibility: 'hidden' }, // Initially hidden scrollbar
//               '&:hover::-webkit-scrollbar': { visibility: 'visible' }, // Show scrollbar when hovering or scrolling
//               '::-webkit-scrollbar-track': { background: '#444' },
//               '::-webkit-scrollbar-thumb': {
//                 background: '#888',
//                 borderRadius: '8px',
//               },
//               '::-webkit-scrollbar-thumb:hover': { background: '#555' },
//             }}
//           >
//             <ChatMessages
//               conversationHistory={conversationHistory}
//               typingIndicator={typingIndicator}
//               scrollViewRef={scrollViewRef}
//               MAX_MESSAGES={MAX_MESSAGES}
//             />
//           </Box>

//           <Box sx={{ position: 'relative', width: '100%' }}>
//             {/* Input Field with Predefined Questions */}
//             <Box sx={{ position: 'absolute', bottom: '0px', width: '100%', px: 2 }}>
//               <QuestionInput
//                 question={question}
//                 setQuestion={setQuestion}
//                 handleQuestionSubmit={handleQuestionSubmit}
//                 sending={sending}
//                 predefinedQuestions={predefinedQuestions}
//               />

             
//             </Box>
//           </Box>
//         </Paper>
//       </Container>
//     </Box>
//   );
// };

// export default LegislationScreen;




