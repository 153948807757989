import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

export default function HomePage() {
  return (
    <Container disableGutters maxWidth={false} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5' }}>
      
      {/* Hero Section with both Chatbot and Ballot Bot Buttons */}
      <Box 
        sx={{ 
          padding: '80px 20px', 
          backgroundColor: '#F5F5F5',  
          color: '#333',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {/* Chatbot Section */}
        <Typography variant="h2" gutterBottom sx={{ color: '#C5B358', fontWeight: 'bold', textAlign: 'center', maxWidth: '900px', fontSize: '2.8rem' }}>
          Become a more informed voter with VoteU.AI
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', maxWidth: '900px', fontSize: '1.3rem' }}>
          Chat with our AI assistant to learn about candidates, policies, and how to register to vote.
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: '#5C6E87', color: '#FFFFFF', marginTop: '20px', fontSize: '1rem' }} href="/chatbot">
          Get Started with our Chatbot
        </Button>

        {/* Ballot Bot Section */}
        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', maxWidth: '900px', marginTop: '40px', fontSize: '1.3rem' }}>
          Want to know what’s on your local ballot? Discover key details with our Ballot Bot!
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: '#5C6E87', color: '#FFFFFF', marginTop: '20px', fontSize: '1rem' }} href="/landingpage">
          Get Started with The Ballot Bot
        </Button>
      </Box>

      {/* Our Mission Section */}
      <Box sx={{ padding: '70px 40px', backgroundColor: '#FFFFFF', color: '#333333', width: '100%' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'left' }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '2.4rem' }}>
            Our Mission
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px', fontSize: '1.2rem' }}>
            We are revolutionizing voter education by making it easy for young people to access crucial information. With AI at the forefront, we provide real-time, personalized, and location-specific data so that you are always in the know about policies, politicians, and your nearest voting booths.
          </Typography>
        </Container>
      </Box>

      {/* Why VoteU Section (Updated Color Scheme) */}
      <Box sx={{ padding: '70px 40px', backgroundColor: '#5C6E87', color: '#FFFFFF', width: '100%' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'left' }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '2.4rem' }}>
            Why VoteU?
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '30px', fontSize: '1.2rem' }}>
            VoteU is your personalized, AI-driven tool designed to make you an informed voter. Whether it's national elections or understanding local issues, VoteU provides you with key information that’s tailored to your interests and your location.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box>
              <Typography variant="h6" sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '1.4rem' }}>Personalized AI Insights</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>Our AI analyzes your interests and provides tailored voting information, from local elections to national policies.</Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '1.4rem' }}>Location-Specific Info</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>Find your polling place, learn about city-specific policies, and receive details about local politicians.</Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '1.4rem' }}>Stay Updated</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>Get timely updates on voter registration deadlines, local elections, and more — tailored just for you.</Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Footer Section */}
      <Box 
        sx={{ 
          backgroundColor: '#E0E0E0',  
          padding: '20px 0', 
          textAlign: 'center', 
          color: '#333',
          width: '100%',
          marginTop: 'auto'
        }}
      >
        <Typography variant="h6" sx={{ color: '#C5B358', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Contact Us
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
          Get in touch with us at info@voteu.ai
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
          © 2024 VoteU.ai. All rights reserved. | Terms of Use | Privacy Policy
        </Typography>
      </Box>
    </Container>
  );
}
