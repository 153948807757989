import React, { useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';

const BallotModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#C5B358', color: '#FFFFFF', marginTop: '10px', padding: '10px 20px', borderRadius: '4px' }}
        onClick={handleOpen}
      >
        Get Ballot Information
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Thank You for Supporting VoteUAI!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            We greatly appreciate your support and enthusiasm for VoteUAI. At this time, we are pausing to prepare for the upcoming midterm elections in 2026. Our team is working diligently to enhance our platform and ensure we provide the most accurate and valuable information to empower voters nationwide.
            <br />
            <br />
            Thank you for your patience and understanding as we continue to build a better future for voter education. Stay tuned for updates, and we look forward to serving you during the next election cycle!
            <br />
            <br />
            VoteUAI Team 🇺🇸


          </Typography>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ marginTop: '20px', backgroundColor: '#C5B358', color: '#FFFFFF' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default BallotModal;
